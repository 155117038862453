<template>
<div class="card-box-full">
  <div class="card-box-header">
    <table width="100%">
      <tr>
        <td>Pending Overrides</td>
        <td align="right">
          <div v-if="isApproving" class="spinner-border spinner-border-sm text-primary" role="status">
            <span class="sr-only"></span>
          </div>
        </td>
      </tr>
    </table>
  </div>

  <!-- ----------------------------------------------------------------- -->
  <div v-if="errorMessage">
    <div class="alert alert-danger" style="margin-top: 15px; margin-bottom: 5px" role="alert">{{ errorMessage }}</div>
  </div>

  <!-- ----------------------------------------------------------------- -->
  <div v-if="isRefreshing">
    <div class="spinner-border spinner-border-sm text-primary" role="status" style="margin: 13px 0px 0px 10px">
      <span class="sr-only"></span>
    </div>
  </div>

  <!-- ----------------------------------------------------------------- -->
  <div v-else-if="requests.length == 0">
    <div class="alert alert-warning" style="margin-top: 15px; margin-bottom: 5px" role="alert">There are no pending overrides</div>
  </div>

  <!-- ----------------------------------------------------------------- -->
  <div v-else>

    <table class="table table-responsive-sm" style="margin-top: 10px; margin-bottom: 0px;">
      <thead>
        <tr style="font-size: 13px; border: 2px solid transparent">
          <th>Requestor</th>
          <th>Phone Number</th>
          <th style="text-align:center">Classify</th>
          <th style="text-align:center">Action</th>
          <th>Note</th>
          <th style="text-align:right"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="request in requests">
          <td :class="request.approved != null ? 'approved' : ''" width="120">
            <account :uuid="request.requested_by"></account>
            <div style="font-size: 10px"><timeago :datetime="request.created"></timeago></div>
          </td>
          <td :class="request.approved != null ? 'approved' : ''"><b>{{ request.phone_number }}</b></td>
          <td :class="request.approved != null ? 'approved' : ''" style="text-align:center">
            <span v-if="request.classify" class="badge badge-pill badge-success">YES</span>
            <span v-else class="badge badge-pill badge-secondary">NO</span>
          </td>
          <td :class="request.approved != null ? 'approved' : ''" style="text-align:center">
            <span v-if="request.classify == false" class="badge badge-pill badge-secondary">NONE</span>
            <span v-else-if="request.block" class="badge badge-pill badge-danger">BLOCK</span>
            <span v-else class="badge badge-pill badge-success">ALLOW</span>
          </td>
          <td :class="request.approved != null ? 'approved' : ''" >{{ request.note }}</td>
          <td width="150" align="center">
            <div v-if="request.approved == null">
              <CButton @click="review(request, true)" color="info" size="sm" :disabled="isApproving">
                Approve
              </CButton>
              &nbsp;
              <CButton @click="review(request, false)" color="secondary" size="sm" :disabled="isApproving">
                Deny
              </CButton>
            </div>
            <div v-else>
              <span v-if="request.approved" class="badge badge-pill badge-info">Approved</span>
              <span v-else class="badge badge-pill badge-secondary">Denied</span>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <hr>

    <table width="100%">
      <tr>
        <td width="50%" align="center">
          <CButton @click="reviewAll(true)" color="info" size="sm" style="width: 90%" :disabled="isApproving">
            Approve All Requests
          </CButton>
        </td>
        <td width="50%" align="center">
          <CButton @click="reviewAll(false)" color="secondary" size="sm" style="width: 90%" :disabled="isApproving">
            Deny All Requests
          </CButton>
        </td>
      </tr>
    </table>

  </div>

</div>
</template>

<style>
  .approved {
    opacity: 0.3;
  }
</style>

<script>
export default {
  name: 'Pending-Overrides',
  data() {
    return {
      errorMessage: null,
      isApproving: false,
      isRefreshing: false,
      requests: []
    }
  },
  methods: {
    //--------------------------------------------------------------------------
    review(request, approved) {
      if (this.isApproving) {
        return;
      } else {
        this.isApproving = true;
        this.errorMessage = null;
      }


      var body = JSON.stringify({
        approved: approved,
        phone_numbers: [request.phone_number]
      });
      var that = this;
      var method = "POST";
      var url = "https://number-classifier.platform.robocalls.ai/override/review";
      var requiresSession = true;
      RobocallsAI.shared().execute(method, url, body, requiresSession, {
        onSuccess: function(code, body) {
          // Code = HTTP status code
          // Body = Response from server
          that.isApproving = false;
          if (code != 200) {
            // Something went wrong
            var message = that.parseMessage(body);
            if (message) {
              that.errorMessage = message;
            } else {
              that.errorMessage = "Received a code " + code + " from the service";
            }
          } else {
            request.approved = approved;
          }
        },
        onCancelled: function() {
          // Fired if the request was cancelled
          that.isApproving = false;
        },
        onFailure: function(error) {
          // Fired if the request fails
          that.isApproving = false;
          that.errorMessage = error;
        }
      })

    },
    //--------------------------------------------------------------------------
    reviewAll(approved) {
      if (this.isApproving) {
        return;
      } else {
        this.isApproving = true;
        this.errorMessage = null;
      }

      // Generate the phone numbers
      var phoneNumbers = [];
      for (var x = 0; x < this.requests.length; x++) {
        phoneNumbers.push(this.requests[x].phone_number);
      }

      var body = JSON.stringify({
        approved: approved,
        phone_numbers: phoneNumbers
      });
      var that = this;
      var method = "POST";
      var url = "https://number-classifier.platform.robocalls.ai/override/review";
      var requiresSession = true;
      RobocallsAI.shared().execute(method, url, body, requiresSession, {
        onSuccess: function(code, body) {
          // Code = HTTP status code
          // Body = Response from server
          that.isApproving = false;
          if (code != 200) {
            // Something went wrong
            var message = that.parseMessage(body);
            if (message) {
              that.errorMessage = message;
            } else {
              that.errorMessage = "Received a code " + code + " from the service";
            }
          } else {
            that.requests = [];
          }
        },
        onCancelled: function() {
          // Fired if the request was cancelled
          that.isApproving = false;
        },
        onFailure: function(error) {
          // Fired if the request fails
          that.isApproving = false;
          that.errorMessage = error;
        }
      })
    },
    //--------------------------------------------------------------------------
    parseMessage(body) {
      try {
        var result = JSON.parse(body);
        return result.message;
      } catch (err) {
        return "";
      }
    },
    //--------------------------------------------------------------------------
    processRequests(response) {
      if (!response || !response.requests) {
        this.errorMessage = "Received an unexpected response from the server";
        return;
      }

      var requests = [];
      for (var x = 0; x < response.requests.length; x++) {
        response.requests[x].approved = null;
        requests.push(response.requests[x]);
      }
      this.requests = requests;
    },
    //--------------------------------------------------------------------------
    refresh() {
      if (this.isRefreshing) {
        return;
      } else {
        this.isRefreshing = true;
      }

      var that = this;
      var method = "GET";
      var url = "https://number-classifier.platform.robocalls.ai/override/requests";
      var requiresSession = true;
      RobocallsAI.shared().execute(method, url, "", requiresSession, {
        onSuccess: function(code, body) {
          // Code = HTTP status code
          // Body = Response from server
          that.isRefreshing = false;
          if (code != 200) {
            // Something went wrong
            var message = that.parseMessage(body);
            if (message) {
              that.errorMessage = message;
            } else {
              that.errorMessage = "Received a code " + code + " from the service";
            }
          } else {
            // Parse the accounts
            try {
              var result = JSON.parse(body);
              that.processRequests(result);
            } catch (err) {
              that.errorMessage = "Failed to parse response from the server";
            }
          }
        },
        onCancelled: function() {
          // Fired if the request was cancelled
          that.isRefreshing = false;
        },
        onFailure: function(error) {
          // Fired if the request fails
          that.isRefreshing = false;
          that.errorMessage = error;
        }
      })
    }
  },
  mounted() {
    this.refresh();
  },
}
</script>
